import React from "react";
import { Form, Field } from "@progress/kendo-react-form";

import {
  ValidationInput,
  loginStyle,
  mapping,
  ButtonSolid,
} from "@aim-mf/styleguide";

class Login extends React.Component {
  render() {
    const handleSubmit = (dataItem) => {
      this.props.login(dataItem.email, dataItem.password);
    };

    return (
      <div style={loginStyle.formBgStyle}>
        <div style={{...loginStyle.formPadding ,paddingLeft:"5.5rem"}}>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <form onSubmit={formRenderProps.onSubmit}>
                <fieldset>
                  <legend style={mapping["heading/h1/lightleft"]}>
                    Sign In
                  </legend>
                  <div style={{ height: "4rem" }} />
                  <div style={loginStyle.fieldsPadding}>
                    <Field
                      id={"email"}
                      title={"Username"}
                      name={"email"}
                      style={loginStyle.fieldStyle}
                      component={ValidationInput}
                      hint={"Email"}
                    />
                  </div>
                  <div style={{ height: "1rem" }} />
                  <div style={loginStyle.fieldsPadding}>
                    <Field
                      id={"password"}
                      title={"Password"}
                      name={"password"}
                      style={loginStyle.fieldStyle}
                      type={"password"}
                      component={ValidationInput}
                      hint={"Password"}
                    />
                  </div>
                </fieldset>
                <div style={{ height: "4rem" }} />
                {this.props.loginFail && (
                  <div style={{ color: "red" }}>
                    Incorrect username or password
                  </div>
                )}
                <div style={loginStyle.fieldButtonPadding}>
                  <ButtonSolid
                    name="Sign In"
                    height={loginStyle.loginButtonHeight}
                    width={loginStyle.loginButtonWidth}
                    color="Primary"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  />
                </div>
              </form>
            )}
          />
          <div style={{ height: "2.5rem" }} />
        </div>
      </div>
    );
  }
}
export { Login };
