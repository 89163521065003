import React from "react";
import { loginStyle } from "@aim-mf/styleguide";
import { Login } from "./login";
import { Logo } from "./logo";
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps, nextContext) {}

  render() {
    return (
      <div
        style={Object.assign({}, loginStyle.backgroundImage, {
          backgroundImage: `url(${this.props.LoginBG})`,
        })}
      >
        <div style={{ display: "inline-flex" }}>
          <Logo />
          <div style={loginStyle.logoSeparatorLogin} />
          <Login
            loginFail={this.props.loginFail}
            tenantList={this.props.tenantList}
            login={this.props.login}
            handleForgetPassword={this.handleForgetPassword}
          />
        </div>
      </div>
    );
  }
}

export { LoginPage };
