import React from "react";
import { LoginPage } from "./page/loginPage";
import { LoginBG } from "@aim-mf/styleguide";
import axios from "axios";
import { navigateToUrl } from "single-spa";

const url = "https://tm.forensic-da.com/api";
const config = {
  auth: url + "/auth",
};

const initialState = {
  loginFail: false,
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { loginFail } = state;
  const login = React.useCallback((username, password) => {
    //console.log(tenant);
    axios
      .post(
        config.auth + "/login/",
        {},
        {
          auth: {
            username: username,
            password: password,
          },
        }
      )
      .then((res) => {
        // console.log("you are logged in!");
        sessionStorage.setItem("loginState", true);
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("token", res.data.token);
        navigateToUrl("/dashboard");
      })
      .catch((error) => {
        dispatch({ type: "loginFail" })
        this.setState({
          errormsg: "Username and password does not match",
        });
      });

  });
    return (
      <div>
        <LoginPage login={login} LoginBG={LoginBG} loginFail={loginFail} />
      </div>
    );
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case "loginFail":
      return {
        ...state,
        loginFail: true,
      };
    case "loginSuccess":
      return {
        ...state,
        loginFail: false,
      };
    default:
      throw Error(`Unknown action type '${action.type}'`);
  }
}

export default App;
